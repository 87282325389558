<template>
  <el-upload
    class="simple-uploader _flex _flex _flex-center"
    :action="actionUrl"
    :headers="headers"
    :data="data"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    :on-progress="handleProgress"
    :on-error="handleError"
  >
    <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
    <slot>
      <img :src="uploadPng" alt="" draggable="false" />
      <div class="simple-uploader__title">
        <span class="primary-color _font-weight__600">Upload Drawing </span>
        <span>from computer</span>
      </div>
      <div class="simple-uploader__desc">
        File type: jpg, png, pdf<br />
        File size cannot exceed 100MB
      </div>
    </slot>
  </el-upload>
</template>

<script>
import uploadPng from '@/assets/upload.png'
import Cookies from 'js-cookie'

export default {
  props: {
    data: Object
  },
  data() {
    return {
      uploadPng,
      actionUrl: `${
        process.env.NODE_ENV === 'development' ? '/restApi' : process.env.VUE_APP_REQUEST_URL
      }/tower/file/uploadOneFile`,
      imageUrl: '',
      headers: {
        Authorization: Cookies.get('tower-token')
      },
      loading: null
    }
  },
  methods: {
    handleProgress({ percent }) {
      // 避免 percent 到 100 了数据还在渲染中
      this.loading.text = `uploading... ${
        percent === 100 ? Math.floor(Math.random() * 10 + 90) : Math.floor(percent)
      }%`
    },
    handleError(err) {
      this.loading.close()
      console.log('上传失败', err)
    },
    handleAvatarSuccess(res) {
      this.loading.close()
      this.$emit('success', res.result)
    },
    beforeAvatarUpload(file) {
      console.log(file.type)
      const isJPG = ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)
      const isLt2M = file.size / 1024 / 1024 <= 100

      if (!isJPG) {
        this.$message.error('上传文件只能是 JPG、PNG、PDF 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 100MB!')
      }

      if (isJPG && isLt2M) {
        this.loading = this.$loading({
          target: document.getElementsByClassName('simple-uploader')[0],
          text: 'uploading 0%',
          background: 'rgba(0, 0, 0, 0.6)'
        })
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scope>
.simple-uploader {
  width: 100%;
  height: 100%;
  border: 1px dashed var(--color-black);

  .el-icon-picture {
    font-size: 54px;
    color: #979797;
    display: inline-block;
    margin-bottom: 15px;
  }

  &__title {
    width: 100%;
    font-size: 12px;
    margin-bottom: 20px;
  }

  &__desc {
    font-size: 12px;
    line-height: 18px;
    transform: scale(0.9);
  }
}
</style>
